<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>

    <el-row type="flex" justify="center" class="bg">
      <el-col :span="8">
        <el-card class="box-card">

          <template #header>
            <div class="card-header">
              <h3> Completa la registrazione </h3>
            </div>
          </template>

          <el-form label-width="auto" :model="verificationForm" status-icon :rules="rules" ref="verificationForm">
            <el-form-item label="Inserisci codice di verifica:" prop="value">
              <el-input v-model="verificationForm.value"></el-input>
            </el-form-item>

            <div style="display: flex; justify-content: flex-end;">
              <el-button block type="primary" @click="submit">Verifica</el-button>
            </div>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { verifyAuthenticationToken } from "../api/user.api";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "VerifyToken",
  components: { Header, Footer },
  data() {
    return {
      verificationForm: {
        value: "",
      },
    };
  },
  computed: {
    ...mapGetters(["appUser", "currentUser", "isLogged"])
  },
  methods: {
    ...mapActions(["fetchUser", "loadPageUser", "updateUser"]),
    submit() {
      this.checkToken(this.verificationForm.value);
    },
    checkToken(token) {
      let resPromise = verifyAuthenticationToken(this.$route.params.id, token);
      resPromise.then((result) => {
        if (result.data.message === "Email confermata") {
          this.$message.success("Attivazione avvenuta con successo!");
          this.$router.push({ name: "login" });
        }
      })
    }
  },
  async created() {
    if (this.isLogged) {
      this.$router.push({ name: "dashboard" })
    }
    const userId = this.$route.params.id;
    await this.fetchUser(userId);
  },

}
</script>

<style scoped>
.bg {
  height: 100vh;
  background: #ededed;
  width: 100%;
  align-items: center;
}
</style>